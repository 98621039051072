import { ensureReady } from '@wkda/after-framework';
import { AppContainers, createReactQueryClient } from '@wkda/funnel-components';
import { initialize } from '@wkda/funnel-modules';
import { abSwitch } from '@wkda/funnel-utils';
import qs from 'query-string';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { After } from '../../after/After';
import { initRum } from '../../utils/initRUM';
import { createClientLogger } from './createClientLogger';

/**
 *
 * @param {{
 *  config: import('@wkda/internal-types').IBaseAppConfig;
 *  routes: any;
 *  activateLocale: (locale: string) => void;
 * }} props
 */
export function createClient({ config, routes, activateLocale }) {
  const { locale, features } = config;

  const apmLogger = initRum(config);
  const modules = initialize({
    configuration: config,
    waKey: process.env.WA_KEY,
    apmLogger,
    modulesLogger: createClientLogger(config, apmLogger),
    isServer: false,
  });

  ensureReady(routes).then((data) => {
    // @ts-expect-error
    const abTestingEnabled = abSwitch({
      query: qs.parse(window.location.search) || {},
    });
    const initialData = {
      ...(data?.initialData ?? {}),
      locale,
      abTestingEnabled,
    };

    const queryClient = createReactQueryClient();

    return hydrateRoot(
      document.getElementById('root'),
      <BrowserRouter>
        <AppContainers
          appConfig={config}
          locale={locale}
          features={features}
          initialData={initialData}
          modules={modules}
          activateLocale={activateLocale}
          queryClient={queryClient}
        >
          <After
            data={data ?? {}}
            routes={routes}
            // internal props
            locale={locale}
            appConfig={config}
            modules={modules}
            features={features}
            abTestingEnabled={abTestingEnabled}
            queryClient={queryClient}
          />
        </AppContainers>
      </BrowserRouter>,
    );
  });
}
