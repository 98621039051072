import { getCustomDOIDomainConfig } from '@wkda/marketing-components';

export const defaultCarleadCreationConfig = {
  locale: 'it',
  emailSuggestionEnabled: true,
  bookingUrl: '/appointment/9-0',
  confirmationUrl: '/appointment/9-0/confirmed',
  redirectOnSuccessUrl: '/booking/{hash}/',
  featuresFlags: {
    redirectToSelfEvaluation: true,
    selfEvaHideOnlinePriceBucket: true,
    exactMileage: true,
  },
  stepsTimeTracking: {
    isEnabledFor: ['StepCompound'],
    options: {
      idleTimeout: 20,
      afterIDLETimeoutInMilliseconds: 10 * 1000,
    },
  },
  marketingAttributes: {
    'lead-mileagetextfield-112022-v1': 1,
    'repositioning-202209-v2': 1,
  },
  selfEvaNoBookingPageBucket: 1,
  selfEvaHideOnlinePriceBucket: 1,
  roundedPrice: true,
  isMileageInputEnabled: true,
  unspecifiedMarketingExperiment: true,
  trackLandingPageType: 'lead',
  syncQuestionnaireFieldsToCookie: true,
  minimumMileage: true,
  returningCustomerPopup: ['lead'],
};

export const carleadCreationConsentConfig = {
  ...defaultCarleadCreationConfig,
  doiDomains: getCustomDOIDomainConfig(),
  featuresFlags: {
    ...defaultCarleadCreationConfig.featuresFlags,
    dropOffEmailDelayInMinutes: 20,
  },
};
